<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    data: Array,
    value: [],
    selectKeys: Array,
  },
  data() {
    return {
      configs: {},
      selection: [],
    };
  },
  watch: {
  },
  created() {
    this.getConfigList('team_member_choose_list', true, true);
  },
  methods: {
    afterCheckboxChange() {
      this.$emit('onSelect', this.selectRow);
    },
    afterCheckAllChange(val) {
      this.$emit('onSelect', val.selection);
    },
    afterGetList() {
      this.selection = this.selectKeys || [];
    },
  },

};
</script>

<style>

</style>
