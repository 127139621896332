var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.data },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "name", align: "center", title: "姓名" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "orgName", align: "center", title: "所属组织" },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  field: "operate-btn",
                  align: "center",
                  width: "60",
                  title: "操作",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "div",
                            { staticClass: "setting-btn" },
                            [
                              _c(
                                "Popconfirm",
                                {
                                  attrs: {
                                    title: "确定删除该数据？",
                                    "confirm-button-type": "text",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.deleteItem(rowIndex)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { color: "#f5222d" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2490741206
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }