<template>

  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="data">
      <vxe-table-column field="name" align="center" title="姓名">
      </vxe-table-column>
      <vxe-table-column field="orgName" align="center" title="所属组织">
      </vxe-table-column>
      <vxe-table-column v-if="!disabled" field="operate-btn" align="center" width="60"  title="操作">
        <template v-slot="{ row,rowIndex}">
          <div class="setting-btn">
            <Popconfirm
              title="确定删除该数据？"
              confirm-button-type="text"
              @confirm="deleteItem(rowIndex)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>

</template>

<script>
import { Popconfirm } from 'element-ui';

export default {
  props: {
    data: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popconfirm,
  },
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    data() {
      this.tableData = this.data;
      console.log(this.tableData);
    },
  },
  methods: {
    deleteItem(rowIndex) {
      this.$emit('deleteItem', rowIndex);
    },
  },

};
</script>

<style>

</style>
