<template>
  <div>
    <div class="supplys-title">团队成员</div>
    <div class="terminalInfo-contant-box">
      <div class="terminalInfo-supplys-btn-group">
        <span v-if="!disabled" class="terminalInfo-supplys-btn">
          <el-button icon="el-icon-plus" type="text" @click="()=>openModal()">新增团队成员</el-button>
        </span>
        <TableList :disabled="disabled" style="marginTop: 10px" :data="dataList" @deleteItem="(rowIndex)=>deleteItem(rowIndex)" />
      </div>
    </div>
    <a-modal v-if="dialogVisible" width="800px" :visible="dialogVisible" :title="title" @cancel="closeModal">
      <component :is="formName" :selectKeys="selectKeys" @onSelect="(value)=>onSelect(value)" />
      <div slot="footer">
        <el-button type="primary" @click="()=>submitData()">确定</el-button>
        <el-button @click="()=>closeModal()">取消</el-button>
      </div>
    </a-modal>
  </div>
</template>

<script>

import request from '@/found/utils/request';
import TableList from './table.vue';
import MemberList from './member_list';

export default {
  components: {
    TableList,
    MemberList,
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  name: 'Supplys',
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      operateItemIndex: 0,
      title: '',
      formName: '',
      orgList: [],
      saleCompanyList: [], // 销售公司下拉
      channelList: [], // 渠道下拉
      customerList: [], // 客户用户
      customerList2: [], // 上级用户
      selectItem: [],
      selectKeys: [],
      treeProps: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
      supplyTypeOptions: [], // 供货关系类型
      loading: false,
    };
  },
  watch: {
    value() {
      this.dataList = this.value;
      this.dataList.forEach((item, index) => {
        if (!Array.isArray(this.dataList[index].positionList)) {
          const positionList = [
            {
              positionCode: this.dataList[index].positionCode,
              unionName: `${this.dataList[index].orgName}/${
                this.dataList[index].positionName
              }/${this.dataList[index].fullName}`,
            },
          ];
          this.$set(this.dataList[index], 'positionList', positionList);
          this.dataList[index].positionCode = item.positionCode;
        }
      });
    },
  },

  created() {
  },
  methods: {
    addSupply() {
      this.dataList.unshift({
        supplyType: '',
        customerCode: '',
        orgCode: '',
        positionCode: '',
        details: [],
        positionList: [],
      });
    },

    deleteSupply(index) {
      this.dataList.splice(index, 1);
      if (this.dataList.length === 0) {
        this.dataList.push({
          supplyType: '',
          customerCode: '',
          orgCode: '',
          positionCode: '',
          details: [],
          positionList: [],
        });
        this.$emit('input', []);
      } else {
        this.$emit('input', this.dataList);
      }
    },
    openModal() {
      this.formName = 'MemberList';
      this.dialogVisible = true;
    },
    closeModal() {
      this.dialogVisible = false;
    },
    onSelect(value) {
      this.selectItem = value;
    },
    submitData() {
      const index = this.operateItemIndex;
      let { selectItem } = this;
      const { formName } = this;
      if (formName === 'Shopping') {
        this.dataList[index].dataType = '1';
        selectItem = selectItem.map((item) => ({
          ...item,
          code: item.productCode,
          name: item.productName,
          dataType: '1',
        }));
      } else {
        this.dataList[index].dataType = '2';
        selectItem = selectItem.map((item) => ({
          ...item,
          dataType: '2',
        }));
      }
      // console.log(selectItem);
      // console.log(this.dataList[index].details);
      selectItem = [...selectItem, ...this.dataList[index].details];
      this.$set(this.dataList[index], 'details', selectItem);
      // this.dataList[index]
      this.dialogVisible = false;
      this.$emit('input', this.dataList);
    },

    onChange(type, index, value, positionList) {
      if (type === 'positionList') {
        const data = positionList.find((item) => item.positionCode === value);
        this.dataList[index].orgCode = data.orgCode;
      }
      if (type === 'orgCode' && this.dataList[index].orgCode !== value) {
        this.dataList[index].orgCode = value;
        this.dataList[index].positionCode = '';
      }
      this.$emit('input', this.dataList);
    },
    deleteItem(rowIndex) {
      const index = this.operateItemIndex;
      this.dataList[index].details.splice(rowIndex, 1);
      this.$emit('input', this.dataList);
    },
    getSupplyTypeOptions() {
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'terminal_supply_type',
        })
        .then((res) => {
          this.supplyTypeOptions = res.result ? res.result : [];
        });
    },
    // 改变供货类型
    changeSupplyType(index, value) {
      console.log(index, value);
    },
  },
};
</script>

<style lang = "less" scoped>

</style>
